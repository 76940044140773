/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// import "./static/fonts.css"

exports.shouldUpdateScroll = ({
  routerProps
}) => {
  const { pathname } = routerProps.location
  if (pathname === "/tour" || pathname === "/tour/") {
    const y = localStorage.getItem("tourScrollPos")
    return [0,y]
  } else {
    return [0,0];
  }
}