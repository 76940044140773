module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Craigdarroch Castle","short_name":"CRG","start_url":"/","display":"standalone","icon":"src/data/assets/nav-bar/logo.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"28090c1599b3d2c048ec7a988d16cbfc"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["MontserratBold","MontserratRegular","LoraItalic","LoraRegular"],"urls":["fonts.css"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
